/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Divider, Icon, Stack } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { HiArrowRight } from 'react-icons/hi';
import { AxiosResponse } from 'axios';
import validateSchema from '../../../validations/client';

import Data from './data';
import Contacts from './contacts';
import Information from './information';
import Group from './bonds/group';
import RulesFlow from './bonds/rulesFlow';
import Model from './bonds/model';
import Partners from './bonds/partners';
import PrivateKey from './privateKey';
import {
  bindScope,
  handleFormToClientsModel,
  handleTagsFormToClientModel,
  incrementDecimalNumber,
} from './utils';
import useClients from '../../../hooks/api/clients';
import usePartners from '../../../hooks/api/partners';
import Notifications from './notifications';
import Button from '../../../components/base/v2/button';
import Header from '../../../components/base/v2/formHeader';
import BondTags from '../../../components/bondTags';

import * as S from './styles';
import * as I from '../../../interfaces/client';
import * as I2 from '../../../components/bondTags/interfaces';
import RetentionFlow from './bonds/retentionFlow';

const ClientUpsertForm = ({
  clientData,
  isDisabled,
  handleSubmit,
  loadInfo,
}: I.PropsClientForm): React.ReactElement => {
  const [partners, setPartners] = useState<string[]>(clientData.partners || []);
  const [flow, setFlow] = useState(clientData.flow_id || '');
  const [retentionFlow, setRetentionFlow] = useState(
    clientData.retention_flow_id ?? ''
  );
  const [group, setGroup] = useState(clientData.group_id || '');
  const [model, setModel] = useState(clientData.model_id || '');
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { push } = useHistory();
  const { postBondPartnersClients } = usePartners();
  const {
    updateClient,
    postClientsFlow,
    postClientsGroup,
    postClientsModel,
    deleteClientsFlow,
    postClientsRetentionFlow,
    deleteClientsModel,
  } = useClients();

  const methods = useForm({
    resolver: yupResolver(validateSchema),
    defaultValues: clientData,
  });

  useEffect(() => {
    methods.reset(clientData);
    setIsLoading(false);
    setIsSubmitting(false);
  }, [clientData]);

  const bindPartner = async (chainData: AxiosResponse<I.ClientModel>) => {
    const { data } = chainData;

    if (
      [200, 201, 204].includes(chainData.request.status) &&
      JSON.stringify(partners) !== JSON.stringify(data.partners) &&
      partners.length > 0
    ) {
      partners.forEach(async (partner) => {
        if (!data.partners.includes(partner)) {
          try {
            const res = await postBondPartnersClients({
              id: data.id,
              scopeID: partner,
              version: chainData.data.version,
            });

            if ([200, 201, 204].includes(res.request.status)) {
              toast.success(
                'O cliente foi adicionado ao parceiro com sucesso!'
              );
              chainData.data.version = incrementDecimalNumber(
                Number(chainData.data.version)
              );
            } else {
              const { reason } = JSON.parse(res.request.response);
              toast.warn(
                `Não foi possível adicionar o cliente ao parceiro ${reason}`
              );
            }
          } catch (error) {
            toast.warn(`Não foi possível fazer o vinculo`);
          }
        }
      });
    }

    return chainData;
  };

  const handleUnlinkFlow = async (flowID: string): Promise<void> => {
    setFlow('');
    methods.setValue('flow_id', '');

    try {
      const res = await deleteClientsFlow({
        id: clientData.id,
        flowID,
        version: clientData.version || '',
      });

      if ([200, 201, 204].includes(res.request.status)) {
        toast.success('O cliente foi removido do fluxo com sucesso!');
        loadInfo!();
      } else {
        const { reason } = JSON.parse(res.request.response);
        toast.warn(`Não foi possível remover o cliente do fluxo ${reason}`);
      }
    } catch (error) {
      toast.warn('Não foi possível quebrar o vinculo');
    }
  };

  const handleUnlinkModel = async (modelID: string) => {
    setModel('');
    methods.setValue('model_id', '');

    try {
      const res = await deleteClientsModel({
        id: clientData.id,
        scopeID: modelID,
        version: clientData.version || '',
      });

      if ([200, 201, 204].includes(res.request.status)) {
        toast.success('O cliente foi removido do modelo com sucesso!');
        loadInfo!();
      } else {
        const { reason } = JSON.parse(res.request.response);
        toast.warn(`Não foi possível remover o cliente do modelo ${reason}`);
      }
    } catch (error) {
      toast.warn('Não foi possível quebrar o vinculo');
    }
  };

  const handleTagSubmit = ({ data, type, scope }: I2.FormToScopeModelType) => {
    const formData = handleTagsFormToClientModel({
      client: clientData,
      data,
      type,
    });

    return updateClient({
      id: scope.id,
      data: formData,
      version: scope.version!,
    });
  };

  const handleReloadOrRedirect = (res: AxiosResponse<I.ClientModel>) => {
    if ([200, 201, 204].includes(res.request.status)) {
      if (loadInfo) {
        loadInfo();
      } else {
        push(`/client/info?id=${res.data.id}`);
      }
    } else {
      setIsSubmitting(false);
    }
  };

  const onSubmit = (data: I.ClientModel) => {
    setIsSubmitting(true);

    handleSubmit({ data: handleFormToClientsModel(data) })
      .then((res: AxiosResponse<I.ClientModel>) =>
        bindScope(res, group, 'group', postClientsGroup)
      )
      .then((res: AxiosResponse<I.ClientModel>) =>
        bindScope(res, flow, 'flow', postClientsFlow)
      )
      .then((res: AxiosResponse<I.ClientModel>) =>
        bindScope(res, retentionFlow, 'retention', postClientsRetentionFlow)
      )
      .then((res: AxiosResponse<I.ClientModel>) =>
        bindScope(res, model, 'model', postClientsModel)
      )
      .then(bindPartner)
      .then(handleReloadOrRedirect);
  };

  if (isLoading) {
    return <></>;
  }

  return (
    <>
      {clientData.id && isDisabled && (
        <Header info={clientData} title="Cliente" />
      )}
      {!isLoading && (
        <>
          {clientData.id && (
            <BondTags
              scope={clientData}
              scopeName="client"
              onSubmitScope={handleTagSubmit}
            />
          )}
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <S.Container>
                <Stack spacing="md">
                  <Notifications client={clientData} />
                  <Data isDisabled={isDisabled} />
                  <Divider />
                  <Contacts isDisabled={isDisabled} />
                  <Divider />
                  <Group
                    isDisabled={isDisabled}
                    setValue={setGroup}
                    value={group}
                  />
                  <Divider />
                  <RulesFlow
                    handleUnlinkFlow={handleUnlinkFlow}
                    isDisabled={isDisabled}
                    setValue={setFlow}
                    value={flow}
                  />
                  <Divider />
                  <RetentionFlow
                    isDisabled={isDisabled}
                    setValue={setRetentionFlow}
                    value={retentionFlow}
                  />
                  <Divider />
                  <Model
                    handleUnlinkModel={handleUnlinkModel}
                    isDisabled={isDisabled}
                    value={model}
                    setValue={setModel}
                  />
                  <Divider />
                  <Partners
                    client={clientData}
                    isDisabled={isDisabled}
                    partners={partners}
                    setPartners={setPartners}
                    loadInfo={loadInfo}
                  />
                  <Divider />
                  <Information isDisabled={isDisabled} />
                </Stack>
              </S.Container>

              {!isDisabled && (
                <S.ButtonsContainer>
                  <Button
                    type="submit"
                    isDisabled={isDisabled}
                    colorScheme="green"
                    rightIcon={<Icon as={HiArrowRight} />}
                    isLoading={isSubmitting}
                  >
                    {clientData.id ? 'Publicar Alterações' : 'Publicar Cliente'}
                  </Button>
                </S.ButtonsContainer>
              )}
            </form>
          </FormProvider>
        </>
      )}
      <PrivateKey />
    </>
  );
};

export default ClientUpsertForm;
