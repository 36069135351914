import { MetricsModel } from './interfaces';
import { Buckets } from '../../hooks/api/charts/interface';

const handleValues = (number?: number): number => {
  if (number) {
    return number;
  }
  return 0;
};

export const valueHandler = (
  value: number,
  type: 'percent' | 'time' | 'day'
) => {
  switch (type) {
    case 'percent': {
      const resultValue = handleValues(value) * 100;
      return `${resultValue.toFixed(1)} %`;
    }
    case 'time': {
      const resultValue = handleValues(value) / 60;
      return `${resultValue.toFixed(1)} min`;
    }
    case 'day': {
      const resultValue = handleValues(value);
      return `${resultValue.toFixed(1)} / dia`;
    }
    default:
      return '';
  }
};

export const formatDateToString = (date: Date) => {
  const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  return localDate.toISOString().split('T')[0];
};

export const getPreparedMetrics = (buckets: Buckets[]): MetricsModel => {
  const approvalRate = buckets.map((bucket) => {
    const value = bucket.data.approval_rate * 100;
    return value.toFixed(1);
  });

  const averageAnalysisTime = buckets.map((bucket) => {
    const value = bucket.data.average_analysis_time / 60;
    return value.toFixed(1);
  });

  const chargebackRate = buckets.map((bucket) => {
    const value = bucket.data.chargeback_rate * 100;
    return value.toFixed(1);
  });

  const finishedTransactions = buckets.map((bucket) => {
    const value = bucket.data.finished_transactions;
    return value.toFixed(1);
  });

  const labels = buckets.map((bucket) => bucket.label);

  const loadedMetrics: MetricsModel = {
    approvalRate,
    averageAnalysisTime,
    chargebackRate,
    finishedTransactions,
    labels,
  };

  return loadedMetrics;
};

export const startDateValue = new Date();
export const endDateValue = new Date();
startDateValue.setDate(endDateValue.getDate() - 7);
